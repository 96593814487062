@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.285em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  position: relative;
  vertical-align: top;
  background-color: white;
  border: 1px solid #ccd0d2;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
}

.input:focus, .input.is-focused, .input:active, .input.is-active,
.textarea:focus,
.textarea.is-focused,
.textarea:active,
.textarea.is-active {
  outline: none;
}

.input[disabled], .input.is-disabled,
.textarea[disabled],
.textarea.is-disabled {
  pointer-events: none;
}

.input:hover, .input.is-hovered,
.textarea:hover,
.textarea.is-hovered {
  border-color: #b5b5b5;
}

.input:focus, .input.is-focused, .input:active, .input.is-active,
.textarea:focus,
.textarea.is-focused,
.textarea:active,
.textarea.is-active {
  border-color: #00d1b2;
}

.input[disabled], .input.is-disabled,
.textarea[disabled],
.textarea.is-disabled {
  background-color: whitesmoke;
  border-color: whitesmoke;
  box-shadow: none;
  color: #7a7a7a;
}

.input[disabled]::-moz-placeholder, .input.is-disabled::-moz-placeholder,
.textarea[disabled]::-moz-placeholder,
.textarea.is-disabled::-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input[disabled]::-webkit-input-placeholder, .input.is-disabled::-webkit-input-placeholder,
.textarea[disabled]::-webkit-input-placeholder,
.textarea.is-disabled::-webkit-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input[disabled]:-moz-placeholder, .input.is-disabled:-moz-placeholder,
.textarea[disabled]:-moz-placeholder,
.textarea.is-disabled:-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input[disabled]:-ms-input-placeholder, .input.is-disabled:-ms-input-placeholder,
.textarea[disabled]:-ms-input-placeholder,
.textarea.is-disabled:-ms-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input[type="search"],
.textarea[type="search"] {
  border-radius: 290486px;
}

.input.is-white,
.textarea.is-white {
  border-color: white;
}

.input.is-black,
.textarea.is-black {
  border-color: #0a0a0a;
}

.input.is-light,
.textarea.is-light {
  border-color: whitesmoke;
}

.input.is-dark,
.textarea.is-dark {
  border-color: #363636;
}

.input.is-primary,
.textarea.is-primary {
  border-color: #00d1b2;
}

.input.is-info,
.textarea.is-info {
  border-color: #5c97bf;
}

.input.is-success,
.textarea.is-success {
  border-color: #23d160;
}

.input.is-warning,
.textarea.is-warning {
  border-color: #ffdd57;
}

.input.is-danger,
.textarea.is-danger {
  border-color: #ff3860;
}

.input.is-small,
.textarea.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}

.input.is-medium,
.textarea.is-medium {
  font-size: 1.25rem;
}

.input.is-large,
.textarea.is-large {
  font-size: 1.5rem;
}

.input.is-fullwidth,
.textarea.is-fullwidth {
  display: block;
  width: 100%;
}

.input.is-inline,
.textarea.is-inline {
  display: inline;
  width: auto;
}

.textarea {
  display: block;
  line-height: 1.25;
  max-height: 600px;
  max-width: 100%;
  min-height: 120px;
  min-width: 100%;
  padding: 10px;
  resize: vertical;
}

.checkbox,
.radio {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;
}

.checkbox input,
.radio input {
  cursor: pointer;
  margin-right: 0.5em;
}

.checkbox:hover,
.radio:hover {
  color: #363636;
}

.checkbox.is-disabled,
.radio.is-disabled {
  color: #7a7a7a;
  pointer-events: none;
}

.checkbox.is-disabled input,
.radio.is-disabled input {
  pointer-events: none;
}

.radio + .radio {
  margin-left: 0.5em;
}

.select {
  display: inline-block;
  height: 2.5em;
  position: relative;
  vertical-align: top;
}

.select:after {
  border: 1px solid #00d1b2;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  transform: rotate(-45deg);
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 4;
}

.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.285em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  position: relative;
  vertical-align: top;
  background-color: white;
  border: 1px solid #ccd0d2;
  color: #363636;
  cursor: pointer;
  display: block;
  font-size: 1em;
  outline: none;
  padding-right: 2.5em;
}

.select select:focus, .select select.is-focused, .select select:active, .select select.is-active {
  outline: none;
}

.select select[disabled], .select select.is-disabled {
  pointer-events: none;
}

.select select:hover, .select select.is-hovered {
  border-color: #b5b5b5;
}

.select select:focus, .select select.is-focused, .select select:active, .select select.is-active {
  border-color: #00d1b2;
}

.select select[disabled], .select select.is-disabled {
  background-color: whitesmoke;
  border-color: whitesmoke;
  box-shadow: none;
  color: #7a7a7a;
}

.select select[disabled]::-moz-placeholder, .select select.is-disabled::-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.select select[disabled]::-webkit-input-placeholder, .select select.is-disabled::-webkit-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.select select[disabled]:-moz-placeholder, .select select.is-disabled:-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.select select[disabled]:-ms-input-placeholder, .select select.is-disabled:-ms-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.select select:hover {
  border-color: #b5b5b5;
}

.select select::ms-expand {
  display: none;
}

.select:hover:after {
  border-color: #363636;
}

.select.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large {
  font-size: 1.5rem;
}

.select.is-fullwidth {
  width: 100%;
}

.select.is-fullwidth select {
  width: 100%;
}

.label {
  color: #363636;
  display: block;
  font-weight: bold;
}

.label:not(:last-child) {
  margin-bottom: 0.5em;
}

.help {
  display: block;
  font-size: 0.75rem;
  margin-top: 5px;
}

.help.is-white {
  color: white;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: whitesmoke;
}

.help.is-dark {
  color: #363636;
}

.help.is-primary {
  color: #00d1b2;
}

.help.is-info {
  color: #5c97bf;
}

.help.is-success {
  color: #23d160;
}

.help.is-warning {
  color: #ffdd57;
}

.help.is-danger {
  color: #ff3860;
}

@media screen and (max-width: 768px) {
  .control-label {
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: 769px) {
  .control-label {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5em;
    padding-top: 0.5em;
    text-align: right;
  }
}

.control {
  position: relative;
  text-align: left;
}

.control:not(:last-child) {
  margin-bottom: 0.75rem;
}

.control.has-addons {
  display: flex;
  justify-content: flex-start;
}

.control.has-addons .button,
.control.has-addons .input,
.control.has-addons .select {
  border-radius: 0;
  margin-right: -1px;
  width: auto;
}

.control.has-addons .button:hover,
.control.has-addons .input:hover,
.control.has-addons .select:hover {
  z-index: 2;
}

.control.has-addons .button:focus, .control.has-addons .button:active,
.control.has-addons .input:focus,
.control.has-addons .input:active,
.control.has-addons .select:focus,
.control.has-addons .select:active {
  z-index: 3;
}

.control.has-addons .button:first-child,
.control.has-addons .input:first-child,
.control.has-addons .select:first-child {
  border-radius: 3px 0 0 3px;
}

.control.has-addons .button:first-child select,
.control.has-addons .input:first-child select,
.control.has-addons .select:first-child select {
  border-radius: 3px 0 0 3px;
}

.control.has-addons .button:last-child,
.control.has-addons .input:last-child,
.control.has-addons .select:last-child {
  border-radius: 0 3px 3px 0;
}

.control.has-addons .button:last-child select,
.control.has-addons .input:last-child select,
.control.has-addons .select:last-child select {
  border-radius: 0 3px 3px 0;
}

.control.has-addons .button.is-expanded,
.control.has-addons .input.is-expanded,
.control.has-addons .select.is-expanded {
  flex-grow: 1;
  flex-shrink: 0;
}

.control.has-addons .select select:hover {
  z-index: 2;
}

.control.has-addons .select select:focus, .control.has-addons .select select:active {
  z-index: 3;
}

.control.has-addons.has-addons-centered {
  justify-content: center;
}

.control.has-addons.has-addons-right {
  justify-content: flex-end;
}

.control.has-addons.has-addons-fullwidth .button,
.control.has-addons.has-addons-fullwidth .input,
.control.has-addons.has-addons-fullwidth .select {
  flex-grow: 1;
  flex-shrink: 0;
}

.control.has-icon .icon {
  color: #dbdbdb;
  pointer-events: none;
  position: absolute;
  top: 1.25rem;
  z-index: 4;
}

.control.has-icon .input:focus + .icon {
  color: #7a7a7a;
}

.control.has-icon .input.is-small + .icon {
  top: 0.9375rem;
}

.control.has-icon .input.is-medium + .icon {
  top: 1.5625rem;
}

.control.has-icon .input.is-large + .icon {
  top: 1.875rem;
}

.control.has-icon:not(.has-icon-right) .icon {
  left: 1.25rem;
  transform: translateX(-50%) translateY(-50%);
}

.control.has-icon:not(.has-icon-right) .input {
  padding-left: 2.5em;
}

.control.has-icon:not(.has-icon-right) .input.is-small + .icon {
  left: 0.9375rem;
}

.control.has-icon:not(.has-icon-right) .input.is-medium + .icon {
  left: 1.5625rem;
}

.control.has-icon:not(.has-icon-right) .input.is-large + .icon {
  left: 1.875rem;
}

.control.has-icon.has-icon-right .icon {
  right: 1.25rem;
  transform: translateX(50%) translateY(-50%);
}

.control.has-icon.has-icon-right .input {
  padding-right: 2.5em;
}

.control.has-icon.has-icon-right .input.is-small + .icon {
  right: 0.9375rem;
}

.control.has-icon.has-icon-right .input.is-medium + .icon {
  right: 1.5625rem;
}

.control.has-icon.has-icon-right .input.is-large + .icon {
  right: 1.875rem;
}

.control.is-grouped {
  display: flex;
  justify-content: flex-start;
}

.control.is-grouped > .control {
  flex-basis: 0;
  flex-shrink: 0;
}

.control.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: 0.75rem;
}

.control.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.control.is-grouped.is-grouped-centered {
  justify-content: center;
}

.control.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

@media screen and (min-width: 769px) {
  .control.is-horizontal {
    display: flex;
  }
  .control.is-horizontal > .control {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1;
  }
}

.control.is-loading:after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1rem;
  position: relative;
  width: 1rem;
  position: absolute !important;
  right: 0.75em;
  top: 0.75em;
}

/*
 * Layout Elements
 */
html, body {
  height: 100%;
  font-size: 18px;
  color: #22313f;
  margin: 0;
  background-color: #f5f8fa;
}

body {
  font-family: "Raleway", sans-serif;
  line-height: 1.8;
}

.page-container {
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  margin: 0 auto;
  max-width: 40em;
  flex-grow: 1;
  padding: 0 1em;
}

.full-width {
  width: 100%;
}

/*
 * Formatting
 */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

p {
  margin: 1.5em 0;
}

p a {
  color: #5c97bf;
  text-decoration: none;
  border-bottom: 2px solid #5c97bf;
  transition: .2s;
}

p a:hover {
  background-color: #5c97bf;
  color: #fff;
}

ul {
  margin: 0;
}

textarea, .textarea {
  min-height: inherit;
  height: auto;
}

h2.page-title {
  border-left: 3px solid;
  border-bottom: 3px solid;
  padding: 0 0 .2em .2em;
  display: inline-block;
}

/*
 * Header
 */
header {
  border-bottom: 1px solid #ececec;
  background-color: #5c97bf;
  padding: 1em 0 .2em;
  margin-bottom: 1em;
}

.site-title {
  font-size: 48px;
  text-align: center;
  margin: 0;
  padding: .6em 0 .8em;
}

.site-title a {
  color: #fff;
  text-decoration: none;
}

.site-title a::hover {
  color: #efefef;
}

/*
 * Navigation
 */
nav {
  width: 100%;
  margin: .6em 0;
  background-color: #f5f8fa !important;
}

.nav-menu {
  width: 90%;
  margin: auto;
  padding: 1em;
  list-style: none;
  text-align: center;
}

.nav-item {
  display: inline-block;
  margin: 0 .8em;
}

.nav-item-link {
  text-decoration: none;
  color: #303030;
  font-size: 18px;
  padding-bottom: .2em;
  transition: .2s;
}

.nav-item-link:hover {
  border-bottom: 2px solid #444;
}

/*
 * Main
 */
main {
  flex-grow: 1;
}

/*
 * Footer Elements
 */
@media (max-width: 768px) {
  footer {
    flex-direction: column;
  }
  .copyright {
    text-align: center;
  }
}

footer {
  background-color: #5c97bf;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 2em;
}

.copyright {
  color: #fff;
  margin: .5em 2em;
}

footer > .social-links {
  margin: .5em 2em;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}

.footer-social-link {
  padding: 0 .5em;
}

.footer-social-link > a {
  color: #fff;
}

.footer-social-link > a:hover {
  color: #ccc;
}

/*
 * Home Page
 */
section.home-intro .container {
  max-width: 50em;
}

.salutaion {
  font-size: 30px;
  font-weight: 600;
}

section.social .social-links > ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}

section.social .social-link {
  padding: 1em;
}

section.social .social-link > a {
  color: #aaa;
}

section.social .social-link > a:hover {
  color: #5c97bf;
}

/*
  * Admin Layout
  */
.logout {
  padding-right: 2em;
}

.logout a {
  color: white;
  text-decoration: none;
}

.logout a:hover {
  text-decoration: underline;
}

.admin-nav {
  background-color: #bbb;
  width: 100%;
}

.auth-links > ul {
  list-style: none;
}

.admin-nav-item {
  list-style-type: none;
}

.admin-nav-item a {
  color: #fff;
  text-decoration: none;
}

.admin-nav-item a:hover {
  text-decoration: underline;
}

.auth-name {
  color: #fff;
}

.auth-name:hover {
  color: #ddd;
  text-decoration: none;
}

.admin-actions {
  display: flex;
  margin-top: 1em;
}

.admin-action:not(:last-child) {
  margin-right: 10px;
}

/*
 * Page Elements
 */
.page-title {
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.page-intro {
  font-size: 22px;
  text-align: center;
  margin: 1em 0 4em 0;
}

/*
  * Blog
  */
.post-preview {
  margin-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px dotted #cdcdcd;
}

.post-preview:hover {
  border-bottom: 2px solid #0f70a2;
}

.post-preview:hover a .post-title, .post-preview:hover a .post-excerpt {
  color: #3080aa;
}

.post-preview > a {
  text-decoration: none;
}

.post-preview > a > .post-title {
  font-size: 30px;
  margin-bottom: 0;
  line-height: 36px;
  text-decoration: none;
}

.post-title {
  font-size: 38px;
  font-weight: bold;
  color: #22313f;
  margin-bottom: .5em;
}

.post-published-on {
  margin: .5em 0;
  color: #777;
}

.post-meta {
  color: #999;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.post-excerpt {
  padding-top: .5em;
  color: #363636;
}

.dot-divider {
  display: none;
}

.dot-divider:after {
  content: "\2219";
}

@media (min-width: 768px) {
  .post-meta {
    flex-direction: row;
  }
  .dot-divider {
    display: block;
    margin: 0 .4em;
  }
}

.post-content {
  margin: 2em 0;
}

pre {
  margin: .5em 0;
}

code {
  font-size: 15px;
  border-radius: 3px;
  border: 1px solid #cdcdcd;
}

/*
 * Misc
 */
.mb-2e {
  margin-bottom: 2em;
}

.mt1 {
  margin-top: 2em;
}

.mt-1e {
  margin-top: 1em;
}

.headshot {
  border-radius: 50%;
  border: 3px solid #5c97bf;
}

.flex-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-center {
  text-align: center;
}

.bg-g1 {
  padding: 1em 0;
  background-color: #efefef;
}

hr {
  height: 2px;
  background-color: #22313f;
  width: 200px;
  margin: 2.5em auto;
}

.mtb-1e {
  margin-top: 1em;
  margin-bottom: 1em;
}

.right {
  text-align: right;
}

/*
 * Grid
 */
.flex-row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 769px) {
  .flex-row {
    display: block;
  }
}

.col {
  flex: 1;
  padding: 0.75rem;
}

@media (min-width: 769px) {
  .col.col-3 {
    flex: none;
    width: 25%;
    padding: 0.75rem;
  }
  .col.col-4 {
    flex: none;
    width: 33.3333%;
    padding: 0.75rem;
  }
}

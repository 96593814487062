/*
 * Layout Elements
 */

html, body {
    height: 100%;
    font-size: 18px;
    color: #22313f;
    margin: 0;
    background-color: $body-bg;
}

body {
    font-family: "Raleway", sans-serif;
    line-height: 1.8;
}

.page-container {
    padding: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    margin: 0 auto;
    max-width: 40em;
    flex-grow: 1;
    padding: 0 1em;
}

.full-width {
    width: 100%;
}

/*
 * Formatting
 */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

p {
    margin: 1.5em 0;
}

p a {
    color: $blue;
    text-decoration: none;
    border-bottom: 2px solid $blue;
    transition: .2s;
    &:hover {
        background-color: $blue;
        color: #fff;
    }
}

ul {
    margin: 0;
}

textarea, .textarea {
    min-height: inherit;
    height: auto;
}


h2.page-title {
    border-left: 3px solid;
    border-bottom: 3px solid;
    padding: 0 0 .2em .2em;
    display: inline-block;
}

/*
 * Header
 */

header {
    border-bottom: 1px solid #ececec;
    background-color: $blue;
    padding: 1em 0 .2em;
    margin-bottom: 1em;
}

.site-title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: .6em 0 .8em;
}

.site-title a {
    color: #fff;
    text-decoration: none;
    &::hover {
        color: #efefef;
    }
}

/*
 * Navigation
 */

nav {
    width: 100%;
    margin: .6em 0;
    background-color: #f5f8fa !important;
}

.nav-menu {
    width: 90%;
    margin: auto;
    padding: 1em;
    list-style: none;
    text-align: center;
}

.nav-item {
    display: inline-block;
    margin: 0 .8em;
}

.nav-item-link {
    text-decoration: none;
    color: #303030;
    font-size: 18px;
    padding-bottom: .2em;
    transition: .2s;
    &:hover {
        border-bottom: 2px solid #444;
    }
}

/*
 * Main
 */

main {
    flex-grow: 1;
}

/*
 * Footer Elements
 */

@media (max-width: 768px) {
    footer {
        flex-direction: column;
    }

    .copyright {
        text-align: center;
    }
}

footer {
    background-color: $blue;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 2em;
}

.copyright {
    color: #fff;
    margin: .5em 2em;
}

footer > .social-links {
    margin: .5em 2em;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
}

.footer-social-link {
    padding: 0 .5em;
}

.footer-social-link > a {
    color: #fff;
}

.footer-social-link > a:hover {
    color: #ccc;
}

/*
 * Home Page
 */

section.home-intro .container {
    max-width: 50em;
}

.salutaion {
    font-size: 30px;
    font-weight: 600;
}

section.blog {

}

section.social {
    .social-links > ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: center;
    }

    .social-link {
        padding: 1em;
    }

    .social-link > a {
        color: #aaa;
    }

    .social-link > a:hover {
        color: $blue;
    }
}










 /*
  * Admin Layout
  */

.logout {
    padding-right: 2em;
    a {
        color: white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.admin-nav {
    background-color: #bbb;
    width: 100%;
}

.auth-links > ul {
    list-style: none;
}

.admin-nav-item {
    list-style-type: none;
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.auth-name {
    color: #fff;
}

.auth-name:hover {
    color: #ddd;
    text-decoration: none;
}

.admin-actions {
    display: flex;
    margin-top: 1em;
}

.admin-action:not(:last-child) {
    margin-right: 10px;
}

/*
 * Page Elements
 */

.page-title {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
}

.page-intro {
    font-size: 22px;
    text-align: center;
    margin: 1em 0 4em 0;
}

 /*
  * Blog
  */

.post-preview {
    margin-top: 2em;
    padding-bottom: 2em;
    border-bottom: 1px dotted #cdcdcd;
    &:hover {
        border-bottom: 2px solid #0f70a2;
        a {
            .post-title, .post-excerpt {
                color: #3080aa;
            }
        }
    }
}

.post-preview > a {
    text-decoration: none;
}

.post-preview > a > .post-title {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 36px;
    text-decoration: none;
}

.post-title {
    font-size: 38px;
    font-weight: bold;
    color: #22313f;
    margin-bottom: .5em;
}

.post-published-on  {
    margin: .5em 0;
    color: #777;
}

.post-meta {
    color: #999;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.post-excerpt {
    padding-top: .5em;
    color: #363636;
}

.dot-divider {
    display: none;
 }

.dot-divider:after {
    content: "\2219";
}

@media (min-width: 768px) {
    .post-meta {
        flex-direction: row;
    }
    .dot-divider {
        display: block;
        margin: 0 .4em;
    }
}

.post-content {
    margin: 2em 0;
}

pre {
    margin: .5em 0;
}

code {
    font-size: 15px;
    border-radius: 3px;
    border: 1px solid #cdcdcd;
}

/*
 * Misc
 */

.mb-2e {
    margin-bottom: 2em;
}

.mt1 {
    margin-top: 2em;
}

.mt-1e {
    margin-top: 1em;
}

.headshot {
    border-radius: 50%;
    border: 3px solid $blue;
}

.flex-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-center {
    text-align: center;
}

.bg-g1 {
    padding: 1em 0;
    background-color: #efefef;
}

hr {
    height: 2px;
    background-color: #22313f;
    width: 200px;
    margin: 2.5em auto;
}

.mtb-1e {
    margin-top: 1em;
    margin-bottom: 1em;
}

.right {
    text-align: right;
}

/*
 * Grid
 */

.flex-row  {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 769px) {
    .flex-row {
        display: block;
    }
}

.col {
    flex: 1;
    padding: 0.75rem;
}

@media (min-width: 769px) {
    .col.col-3 {
        flex: none;
        width: 25%;
        padding: 0.75rem;
    }

    .col.col-4 {
        flex: none;
        width: 33.3333%;
        padding: 0.75rem;
    }
}